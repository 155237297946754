

import { defineComponent, ref, onMounted, computed, onUpdated } from "vue";
import ExportCustomerModal from "@/components/modals/forms/ExportCustomerModal.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";


export default defineComponent({

  name: "attribute-listing",
  components: {
    ExportCustomerModal,
  },

  setup() {
    
    const count = ref(0)
    const store = useStore();
    const checkedCompany = ref([]);    
    const loadingData = ref<boolean>(true);

    interface Product {
      attribute_id: number,      
      
      active: {
        label: string;
        color: string;
      },   
      attribute_name: string,
      attribute_display_name: string,
      
    }

    var paginationData = ref({})
    
    paginationData.value = {      
         start : 0,
          end : 0,
          total: 0,        
        perPage : 5,
        activePage : 1,
        totPage : 1,        
        pageRange : [1]
    }

     const refreshData =() => {      
      paginationData.value['activePage']  = 1;      
      getProduct()
    }

    const changePageChange = (page) => {      
      paginationData.value['activePage']  = page;      
      getProduct()
    }
    
    
    var tableData = ref<Array<Product>>([]);
    
   
     const getProduct = async () => {
      
      loadingData.value = true;

      try {

        var values = { "attribute_id"  :  0, "page"  : parseInt(paginationData.value['activePage']), "records_per_page" : parseInt(paginationData.value['perPage']) }

        await store.dispatch(Actions.CUST_GET_PRODUCT_ATTRIBUTE_LIST, values).then(({ data }) => {

          console.log(data);
          
          tableData.value = ([]);

          // set pagination
          paginationData.value['total'] = data.total_records;        
          paginationData.value['start'] = data.records_from
          paginationData.value['end'] = data.records_upto
          paginationData.value['activePage'] = data.page;
          paginationData.value['totPage']  = data.total_pages
          paginationData.value['pageRange'] = []
          for (let index =  data.bar_range_start; index < data.bar_range_end; index++) {
            paginationData.value['pageRange'].push(index)
          }
          //endpagination
          
          var resultsM = ref<Array<Product>>([])
          var status_label = ""
          var status_color = ""
          var active_label = ""
          var active_color = ""        

          for (let j = 0; j < data.result_list.length; j++) {

            if (data.result_list[j]['active']){
                active_label = "Yes";
                active_color = "success";                  
            }else{
                active_label = "No";
                active_color = "danger";
            }
            
            resultsM.value = Array({
              attribute_id : data.result_list[j]['attribute_id'],
              
              active: {
                label: active_label,
                color: active_color
              },
              attribute_name: data.result_list[j]['attribute_name'],
              attribute_display_name: data.result_list[j]['attribute_display_name'],

            })
            console.log("Result")
            console.log(resultsM.value)
            tableData.value.splice(j, resultsM.value.length, ...resultsM.value);      
            console.log("Table")
            console.log(tableData.value)
          }

          loadingData.value = false;

        }).catch(({ response }) => {
          console.log("catch")
          tableData.value = [];
          loadingData.value = false;

       });

      } catch (e) {
        console.log(e);
      }
    };

      onUpdated(() => {          
        // console.log("aaap");
        // console.log(list_data);
      });

      const initCustomers = ref<Array<Product>>([]);                      

      onMounted( async () => {
        await getProduct()
        setCurrentPageBreadcrumbs("Products Attribute", []);
        initCustomers.value.splice(0, tableData.value.length, ...tableData.value);        
        console.log(tableData.value.length);
      });  

      const deleteFewCustomers = () => {
        checkedCompany.value.forEach((item) => {
          deleteCustomer(item);
        });
        checkedCompany.value.length = 0;
      };

      const deleteCustomer = (id) => {
        for (let i = 0; i < tableData.value.length; i++) {
          if (tableData.value[i].attribute_id === id) {
            tableData.value.splice(i, 1);
          }
        }
      };

      const search = ref<string>("");
      const searchItems = () => {
        tableData.value.splice(0, tableData.value.length, ...initCustomers.value);        
        if (search.value !== "") {
          let results: Array<Product> = [];          
          for (let j = 0; j < tableData.value.length; j++) {
            if (searchingFunc(tableData.value[j], search.value)) {              
              results.push(tableData.value[j]);
            }
          }
          tableData.value.splice(0, tableData.value.length, ...results);
        }
      };

      const searchingFunc = (obj, value): boolean => {        
        for (let key in obj) {          
          if (!Number.isInteger(obj[key]) && !(typeof obj[key] === "object")) {            
            if (obj[key].indexOf(value) != -1) {
              
              return true;
            }
          }
        }
        return false;
      };          
       
    return {
      tableData,      
      deleteCustomer,
      search,
      searchItems,
      checkedCompany,
      deleteFewCustomers,
      count,      
      getProduct,       
      paginationData,
      changePageChange,
      refreshData,
      loadingData
    };  

  }  

});




